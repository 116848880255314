<template>
  <cw-page
    icon="person"
    class="car-loan__start"
  >
    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <v-row>
        <v-col id="form-element__ssn" cols="12" sm="6">
          <cw-text-field
            id="ssn"
            v-model="form.ssn"
            :hint="ssnHint"
            :label="ssnLabel"
            counter="11"
            rules="required|ssn"
            type="text"
            anonymize
            uppercase
            prevent-set-data
          />
        </v-col>
        <v-col id="form-element__phone" cols="12" sm="6">
          <cw-text-field
            id="phone"
            v-model="form.phone"
            :hint="phoneHint"
            :label="phoneLabel"
            rules="required|phone"
            type="tel"
            anonymize
            prevent-set-data
          />
        </v-col>
        <v-col id="form-element__email" cols="12" sm="6">
          <cw-text-field
            id="email"
            v-model="form.email"
            :hint="emailHint"
            :label="emailLabel"
            rules="required|email"
            type="email"
            anonymize
            prevent-set-data
          />
        </v-col>
        <v-col id="form-element__marketingAllowed" cols="12">
          <cw-checkbox
            id="marketingAllowed"
            v-model="form.marketingAllowed"
            class="mt-0"
            hide-details
            prevent-set-data
          >
            <template #label>
              <span data-test>
                {{ marketingAllowedLabel }}
              </span>
            </template>
          </cw-checkbox>
          <div class="pl-8 text-caption">
            <translate>
              I want to receive marketing messages and newsletters via SMS and Email.
            </translate>
          </div>
        </v-col>
        <v-col id="form-element__ringData" cols="12">
          <cw-checkbox
            id="ringData"
            v-model="form.ringData"
            class="mt-0"
            rules="required"
            hide-details
            prevent-set-data
          >
            <template #label>
              <span data-test>
                <translate>I agree to reviewing customer information</translate>
              </span>
            </template>
          </cw-checkbox>
          <div class="pl-8 text-caption">
            <translate>
              The consumer credit survey system maintained by Suomen Asiakastieto Oy is used for
              checking.
            </translate>
            <a
              id="dataGatheringPermission--link"
              href="#"
              @click.prevent.stop="
                $bus.$emit('OPEN_CONFIRMATION_DIALOG', {
                  component: 'DialogDataGatheringPermission',
                  folder: 'fi',
                });
                $eventLogger.clickEvent($event);
              "
            >
              <translate>More information</translate>
            </a>
          </div>
        </v-col>
        <v-col class="basic-information__dataHandlingPermission--wrapper" cols="12">
          <cw-checkbox
            id="dataHandlingPermission"
            v-model="form.dataHandlingPermission"
            class="mt-0"
            rules="required"
            hide-details
            prevent-set-data
          >
            <template #label>
              <span data-test>
                <translate>
                  I agree to the storage of my information in the Service and the Company's Privacy
                  Policy.
                </translate>
              </span>
              <a
                v-if="isBrokerFlow()"
                id="dataHandlingPermission--link"
                target="_blank"
                href="/pdf/Tact-Finance-Broker-Tietosuojaseloste.pdf"
                @click.stop
              >
                <translate>Privacy Policy</translate>
              </a>
              <a
                v-else
                id="dataHandlingPermission--link"
                target="_blank"
                href="/pdf/privacy-policy-fi.pdf"
                @click.stop
              >
                <translate>Privacy Policy</translate>
              </a>
            </template>
          </cw-checkbox>
        </v-col>
      </v-row>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :disabled="$wait.waiting('SUBMIT_FORM')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        color="purple-one"
        @click="
          validateForm();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Continue
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { goToError } from '@shared/mixins';

export default {
  name: 'CwCarLoanApplicationStart',

  components: {
    'cw-checkbox': () => import('@shared/components/inputs/CwCheckbox'),
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data: () => ({
    form: {
      email: '',
      marketingAllowed: 'off',
      phone: '',
      ringData: 'off',
      ssn: '',
      dataHandlingPermission: 'off',
    },
  }),

  computed: {
    /**
     * Gettext translations
     */
    ssnHint: vm => vm.$gettext('Identity number in the form of ddmmyy-xxxx'),
    ssnLabel: vm => vm.$gettext('Identity number'),
    phoneHint: vm => vm.$gettext('Phone number in the form of 0401234567'),
    phoneLabel: vm => vm.$gettext('Phone number'),
    emailHint: vm => vm.$gettext('Email in the form of name@example.com'),
    emailLabel: vm => vm.$gettext('Email'),
    marketingAllowedLabel: vm => vm.$gettext('I want to receive marketing messages'),
  },

  methods: {
    ...mapActions({
      submitForm: 'application/submit',
    }),
    ...mapGetters({
      isBrokered: 'application/isBrokered',
    }),

    isBrokerFlow() {
      return this.isBrokered();
    },

    async validateForm() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        this.goToError();

        return;
      }

      await this.submit();
    },

    async submit() {
      this.$wait.start('SUBMIT_FORM');

      const formParams = { ...this.form };

      try {
        await this.submitForm({
          formParams,
          routineParams: 'submitCarLoanStart',
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>
